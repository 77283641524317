<template>
  <vue-word-cloud
    :color="([, weight]) => weight > 14 ? mergedOptions.colors.high : weight > 7 ? mergedOptions.colors.mid : mergedOptions.colors.low"
    :words="dataProp.slice(0, 40)"
    :font-family="mergedOptions.font"
    :leaveAnimation="{opacity: 1}"
    :enterAnimation="{opacity: 1}"
    :animationDuration="0"
    :style="style"></vue-word-cloud>
</template>

<script>
// /////////////////
// This widget s not ready for dashboard yet!!
// only use this for photo booth renderer
// /////////////////
import VueWordCloud from 'vuewordcloud'
import { deepSpread } from 'core-ui/utils/deepSpread'

export default {
  name: 'WordCloud',
  components: { [VueWordCloud.name]: VueWordCloud },
  props: {
    dataProp: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => {}
    },
    dimensions: {
      type: Object
    }
  },
  computed: {
    mergedOptions () {
      const defaults = {
        colors: {
          high: 'DeepPink',
          mid: 'Indigo',
          low: 'RoyalBlue'
        },
        font: 'Roboto'
      }
      return deepSpread(defaults, this.options)
    },
    style () {
      if (!this.dimensions) {
        return undefined
      }
      return `width: ${this.dimensions.width - 15}px; height: ${this.dimensions.height - 15}px;`
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Caveat&family=Patrick+Hand&family=Indie+Flower&family=Londrina+Outline');
</style>
